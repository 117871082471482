<template>
  <div class="mr-4">
    <v-col cols="12">
      <v-subheader>Job Condition</v-subheader>
        <div class="d-flex mb-6">
          <v-select
            class="mr-2"
            dense
            v-model="optionCondition.tel_type"
            :items="telType"
            item-text="keys"
            item-value="value"
            label="Tel Type"
            outlined></v-select>
          <v-select
            class="mr-2"
            dense
            v-model="optionCondition.device"
            :items="OS"
            label="OS Type"
            outlined></v-select>
          <v-select
            class="mr-2"
            dense
            v-model="optionCondition.conditionAVN"
            :items="condition"
            item-text="keys"
            item-value="value"
            label="Condition AVN"
            outlined></v-select>
          <v-text-field
            class="mr-2"
            label="App Version (AVN)"
            v-model.trim="optionCondition.app_version_name"
            dense
            outlined />
        </div>
        <div class="d-flex mb-6">
          <!-- <v-select
            class="mr-2"
            dense
            v-model="optionCondition.conditionAge"
            :items="loginType"
            item-text="keys"
            item-value="value"
            label="Login Type"
            outlined></v-select> -->
          <!-- <v-select
            class="mr-2"
            dense
            v-model="optionCondition.conditionAge"
            :items="condition"
            item-text="keys"
            item-value="value"
            label="Condition Age"
            outlined></v-select> -->
          <v-menu
            v-model="optionCondition.dateFirst"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                class="mr-2"
                v-model="dateFirstRangeText"
                dense
                outlined
                label="First Login"
                hint="DD/MM/YYYY format"
                persistent-hint
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on" />
            </template>
            <v-date-picker
              v-model="optionCondition.create_date"
              range
              no-title
              @input="optionCondition.dateFirst = true" />
          </v-menu>
          <v-menu
            v-model="optionCondition.dateLast"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                dense
                outlined
                label="Last Login"
                hint="DD/MM/YYYY format"
                persistent-hint
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on" />
            </template>
            <v-date-picker
              v-model="optionCondition.last_update"
              range
              no-title
              @input="optionCondition.dateLast = true" />
          </v-menu>
          <v-text-field
            class="mx-2"
            color="primary"
            label="Total Estimate"
            disabled
            v-model.trim="jobInfo.totalEstimateIds"
            dense
            outlined />
          <v-btn
            class="mx-2"
            color="red"
            outlined
            @click="resetEstimate">
            <v-icon class="mr-5">mdi-comment-processing</v-icon>
            RESET 
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="estimateCondition">
            <v-icon class="mr-5">mdi-comment-processing</v-icon>
            ESTIMATE
          </v-btn>
        </div>
      <v-progress-linear
        :active="estimateStatus"
        :indeterminate="estimateStatus"
        color="cyan accent-4"
        rounded
        height="6" />
    </v-col>
    <v-divider />
    <v-col cols="12">
      <v-subheader>Job Infomation</v-subheader>
      <v-text-field
        label="Job Name"
        v-model="jobInfo.name"
        dense
        outlined
        required />
      <v-row>
        <v-col cols="6"
            class="mt-2">
          <v-menu
            v-model="jobDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                dense
                label="Date Start Job"
                hint="DD/MM/YYYY format"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on" />
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="jobDate = false" />
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-text-field
            class="pt-0"
            v-model="time"
            :rules="[rules.timeFormat]"
            label="Job Time"
            hint="sample format time 09:00"
            persistent-hint
            prepend-icon="mdi-clock-time-four-outline" />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
// import _ from 'lodash';

export default {
  name: 'loadJobsInfo',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data: (vm) => ({
    date: moment().format('YYYY-MM-DD'),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    jobDate: false,
    jobTime: '',
    time: null,
    ids: '',
    files: [],
    rules: {
      timeFormat: value => {
          const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
          return (
            pattern.test(value) ||
            'Please, check time 24Hr. patten. Ex: "09:00", "13:00"'
          );
        },
    },
    loginType: [
      { keys: 'First Login', value: 'FirstLogin' },
      { keys: 'Last Login', value: 'LastLogin' }
    ],
    telType: [
      {
        keys: 'All',
        value: 'All'
      },
      {
        keys: 'Postpaid',
        value: 'T'
      },
      {
        keys: 'Prepaid',
        value: 'P'
      }
    ],
    OS: ['All', 'ios', 'android'],
    condition: [
      {
        keys: 'Equal ( = )',
        value: 'equal'
      },
      {
        keys: 'Greater Than ( > )',
        value: 'greater than'
      },

      {
        keys: 'Greater Than Or Equal ( >= )',
        value: 'greater than or equal'
      },

      {
        keys: 'Less Than ( < )',
        value: 'less than'
      },

      {
        keys: 'Less Than Or Equal ( <= )',
        value: 'less than or equal'
      },

      {
        keys: 'Not Equal ( != )',
        value: 'not equal'
      },
    ],
    optionCondition: {
      tel_type: '',
      device: '',
      conditionAVN: '',
      app_version_name: '',
      conditionAge: '',
      dateFirst: false,
      create_date: [],
      dateLast: false,
      last_update: []
    },
    isConditions: [],
    isTelTypeOption: {
      key: '',
      operator: '',
      value: ''
    },
    isDeviceOption: {
      key: '',
      operator: '',
      value: ''
    },
    isAVNOption: {
      key: '',
      operator: '',
      value: ''
    },
    isCreateDateStartOption: {
      key: '',
      operator: '',
      value: ''
    },
    isCreateDateEndOption: {
      key: '',
      operator: '',
      value: ''
    },
    isLastUpdateStartOption: {
      key: '',
      operator: '',
      value: ''
    },
    isLastUpdateEndOption: {
      key: '',
      operator: '',
      value: ''
    }
  }),
  computed: {
    computedDateFormatted() {
      this.jobInfo.dateJob = this.formatDate(this.date);
      return this.formatDate(this.date);
    },

    computedLastUpdateDateFormatted() {
      this.isLastUpdateOption.value = new Date(this.optionCondition.last_update).getTime();
      // return this.formatDate(this.optionCondition.last_update);
    },

    computedFirstDateFormatted() {
      this.isFirstUpdateOption.value = new Date(this.optionCondition.first_login).getTime();
      // return this.formatDate(this.optionCondition.first_login);
    },

    dateFirstRangeText () {
      const logindRange = [];
      this.optionCondition.create_date.forEach(d => {
        logindRange.push(this.formatDate(d));
      });
      return logindRange.join(' ~ ')
    },

    dateRangeText () {
      const logindRange = [];
      this.optionCondition.last_update.forEach(d => {
        logindRange.push(this.formatDate(d));
      });
      return logindRange.join(' ~ ')
    },

    ...mapGetters({
      jobTotal: 'jobManagement/GET_TOTAL_JOB',
      estimateIds: 'jobManagement/GET_ESTIMATE_JOB_CONDITION',
      estimateStatus: 'jobManagement/GET_LOADING_EXPORT_CUSTOMER_FIELD'
    })
  },
  watch: {
    'optionCondition.tel_type': function (val) {
      if (val !== 'All') {
        this.isTelTypeOption.key = 'tel_type';
        this.isTelTypeOption.operator = 'equal';
        this.isTelTypeOption.value = val;
      } else {
        this.isTelTypeOption.key = 'tel_type';
        this.isTelTypeOption.operator = 'equal';
        this.isTelTypeOption.value = '';
      }
    },

    'optionCondition.device': function (val) {
      if (val !== 'All') {
        this.isDeviceOption.key = 'device';
        this.isDeviceOption.operator = 'equal';
        this.isDeviceOption.value = val;
      } else {
        this.isDeviceOption.key = 'device';
        this.isDeviceOption.operator = 'equal';
        this.isDeviceOption.value = '';
      }
    },

    'optionCondition.app_version_name': function (val) {
      this.isAVNOption.key = 'app_version_name';
      this.isAVNOption.operator = this.optionCondition.conditionAVN;
      this.isAVNOption.value = val;
    },

    'optionCondition.last_update': function (val) {
      const lastUpdateStart = new Date(val[0]);
      const lastUpdateEnd = new Date(val[1]);
      this.isLastUpdateStartOption.key = 'last_update';
      this.isLastUpdateStartOption.operator = 'greater than or equal';
      this.isLastUpdateStartOption.value = lastUpdateStart.getTime();

      this.isLastUpdateEndOption.key = 'last_update';
      this.isLastUpdateEndOption.operator = 'less than or equal';
      this.isLastUpdateEndOption.value = lastUpdateEnd.getTime();
    },

    'optionCondition.create_date': function (val) {
      const createDateStart = new Date(val[0]);
      const createDateEnd = new Date(val[1]);
      this.isCreateDateStartOption.key = 'create_date';
      this.isCreateDateStartOption.operator = 'greater than or equal';
      this.isCreateDateStartOption.value = createDateStart.getTime();

      this.isCreateDateEndOption.key = 'create_date';
      this.isCreateDateEndOption.operator = 'less than or equal';
      this.isCreateDateEndOption.value = createDateEnd.getTime();
    },

    // eslint-disable-next-line no-unused-vars
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },

    time(val) {
      const dayStart = new Date(`${this.date}T${val}`);

      this.jobInfo.startTime = Date.parse(dayStart);
      this.jobInfo.endTime = this.jobInfo.startTime + (1000 * 10);
    },

    ids(val) {
      this.jobInfo.ids = val.split(',');
    }
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    handleFileDrop(e) {
      const droppedFiles = e.dataTransfer.files;

      // eslint-disable-next-line no-useless-return
      if (!droppedFiles) return;

      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      })
    },

    handleFileInput(e) {
      // eslint-disable-next-line prefer-destructuring
      const files = e.target.files;

      // eslint-disable-next-line no-useless-return
      if (!files) return;

      const fd = new FormData();
      ([...files]).forEach(f => {  
        this.files.push(f);
        fd.append('file', f)
      });


      this.$store.dispatch('jobManagement/UPLOAD_FILE_JOBS', fd);
    },

    removeFile(fileKey){
      this.files.splice(fileKey, 1)
    },

    addCondition() {
      this.isConditions = [];
        
      if (this.isTelTypeOption.value !== '') {
        this.isConditions.push(this.isTelTypeOption)
      }

        
      if (this.isDeviceOption.value !== '') {
        this.isConditions.push(this.isDeviceOption);
      }

      if (this.optionCondition.conditionAVN !== '') {
        this.isAVNOption.operator = this.optionCondition.conditionAVN;
        this.isConditions.push(this.isAVNOption);
      }

      // if (this.optionCondition.conditionAge !== '') {
      //   this.isLastUpdateOption.operator = this.optionCondition.conditionAge;
      //   this.isConditions.push(this.isLastUpdateOption);
      // }

      if (this.optionCondition.create_date.length === 2) {
        this.isConditions.push(this.isCreateDateStartOption);
        this.isConditions.push(this.isCreateDateEndOption)
      }

      if (this.optionCondition.last_update.length === 2) {
        this.isConditions.push(this.isLastUpdateStartOption);
        this.isConditions.push(this.isLastUpdateEndOption);
      }
    },

    resetEstimate() {
      this.isConditions = [];

      this.optionCondition.tel_type = '';
      this.optionCondition.device = '';
      this.optionCondition.conditionAVN = '';
      this.optionCondition.app_version_name = '';
      this.optionCondition.conditionAge = '';
      this.optionCondition.dateFirst = false;
      this.optionCondition.create_date = [];
      this.optionCondition.dateLast = false;
      this.optionCondition.last_update = [];
    
      this.isTelTypeOption.key = '';
      this.isTelTypeOption.operator = '';
      this.isTelTypeOption.value = '';
    
      this.isDeviceOption.key = '';
      this.isDeviceOption.operator = '';
      this.isDeviceOption.value = '';

      this.isAVNOption.key = '';
      this.isAVNOption.operator = '';
      this.isAVNOption.value = '';

      this.isCreateDateStartOption.key ='';
      this.isCreateDateStartOption.operator ='';
      this.isCreateDateStartOption.valu = '';

      this.isCreateDateEndOption.key = '';
      this.isCreateDateEndOption.operator = '';
      this.isCreateDateEndOption.valu = '';

      this.isLastUpdateStartOption.key = '';
      this.isLastUpdateStartOption.operator = '';
      this.isLastUpdateStartOption.valu = '';

      this.isLastUpdateEndOption.key = '';
      this.isLastUpdateEndOption.operator = '';
      this.isLastUpdateEndOption.value = '';

    },

    async estimateCondition() {
      this.addCondition();
      await this.$store.dispatch('jobManagement/ESTIMATE_JOB_CUSTOM_CONDITION', { conditions: this.isConditions });
      this.jobInfo.totalEstimateIds = this.estimateIds.amount;
      this.jobInfo.conditions = this.isConditions;
      this.jobInfo.ids = [];
      this.jobInfo.msisdn = [];
    }
  },
}
</script>

<style lang="scss" scoped>
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
</style>